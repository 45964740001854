<script>
import contactService from './../services/contact.service'
export default {
  name: 'ContactPage',
  page: {
    title: 'Επικοινωνία'
  },
  data () {
    return {
      contactForm: {
        name: '',
        surname: '',
        email: '',
        telephone: '',
        message: ''
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      contactService.sendEmail(
        {
          subject: 'Ευαπόδραση - Φόρμα επικοινωνίας',
          data: this.contactForm
        }
      ).then(() => {
        this.$bvToast.toast('Your data has been sent successfully', {
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: 5000
        })
        this.resetForm()
      }).catch(() => {
        this.$bvToast.toast('Error', {
          variant: 'danger',
          autoHideDelay: 5000
        })
      })
    },
    resetForm () {
      this.contactForm = {
        email: '',
        name: '',
        surname: '',
        telephone: '',
        message: ''
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="page-title">
      <b-container class="bv-example-row">
        <h1 class="title">Επικοινωνία</h1>
      </b-container>
    </div>
    <div class="page-body">
      <b-container class="bv-example-row">
        <b-row>
          <b-col sm="7">
            <b-form @submit="onSubmit">
              <b-form-group label="Όνομα *" label-for="name">
                <b-form-input
                  id="name"
                  v-model="contactForm.name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Επώνυμο *" label-for="surname">
                <b-form-input
                  id="surname"
                  v-model="contactForm.surname"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Email *" label-for="email">
                <b-form-input
                  id="email"
                  v-model="contactForm.email"
                  type="email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Τηλέφωνο" label-for="telephone">
                <b-form-input
                  id="telephone"
                  v-model="contactForm.telephone"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Μήνυμα" label-for="message">
                <b-form-textarea
                  id="message"
                  v-model="contactForm.message"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-button type="submit">Αποστολή</b-button>
            </b-form>
          </b-col>
          <b-col sm="5">
            <div class="mt-4 page-text">
              <p class="contact-us-title">
                Μπορείτε να επικοινωνήσετε μαζί μας:
              </p>
              <p>Pobuca SiEBEN ΙΚΕ</p>
              <p>Αριστομένους 3, 15344, Γέρακας, Αθήνα</p>
              <p>Τηλέφωνο: +30 2130 179 100</p>
              <p>FAX: +30 2130 179 001</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "./../assets/scss/abstracts" as *;

.page-title {
  background-color: $white;
  padding: $s-xl 0 $s-s 0;
}

.title {
  color: $black;
  font-family: "Waiting for the Sunrise", cursive;
  padding: $s-s 0;
}

.page-body {
  background-color: $alto;
  color: $black;
  padding: $s-xl 0;
}

.page-text {
  line-height: $s-xl;
}

.contact-us-title {
  font-weight: bold;
  color: $flush-orange;
}
</style>
